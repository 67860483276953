/**
 * ID: bh-global-css
 * Name: global.scss
 * Description: App-specific global themes. Applied after base and canned stylesheets applied.
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 */

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

.ion-page {
  background: var(--background-color);
}

.number-and-question {
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.dark {
  .page-topper {
    &.morning .page-topper-inner h3 {
      color: #fff;
      text-shadow: 1px 1px 3px #000;
    }
    .page-topper-inner {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

body:not(.dark) {
  &.morning .page-topper-inner h3 {
    color: #fff;
    text-shadow: none !important;
  }

  .night .page-topper-inner h3,
  .night .page-topper-inner h4 {
    color: #fff;
  }

  // .page-topper .page-topper-inner h3 {
  //   text-shadow: 1px 1px 3px #fff;
  // }
}

.wide-viewport-container {
  min-height: calc(70% - 85px);
}

.page-topper {
  height: 280px;
  background: url("/assets/spring.png") no-repeat center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.spring {
    background-image: url("/assets/spring.png");
    background-position: center bottom;

    h3,
    h4 {
      color: #fff;
      text-shadow: 1px 1px 3px #000;
    }

  }

  &.summer {
    background-image: url("/assets/summer.png");

    h3,
    h4 {
      color: #fff;
      text-shadow: 1px 1px 3px #000;
    }
  }

  &.autumn {
    background-image: url("/assets/autumn.png");

    h3,
    h4 {
      color: #fff;
      text-shadow: 1px 1px 3px #000;
    }
  }

  &.winter {
    background-image: url("/assets/winter.png");

    h3 {
      color: #000;
    }
  }

  .page-topper-inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .screening-prompt-wrapper {
      margin: 0 16px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        // text-shadow: 1px 1px 3px #000;
        text-align: center;
        margin-top: 0;

        .greeting-text {
          font-weight: normal;
          font-size: 20px;
          margin-bottom: 8px;
          display: block;
        }
      }

      .button-wrapper {
        max-width: 400px;
        margin: 0 auto;

        ion-button {
          min-width: 300px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .page-topper {
    height: 310px;

    &.autumn {
      background-size: auto 100%;
    }
  }
}

.workflow-stepper-wrapper {
  // background: #F6F6F6;
  margin: 0 auto 32px auto;
  padding: 24px 0;
  transition: all 0.4s;

  .workflow-stepper {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    max-width: 850px;
    margin: auto;

    .step {
      border-radius: 5px;
      border: 1px solid #d3d3d3;
      color: #d3d3d3;
      padding: 8px;
      max-width: 140px;
      height: 100px;
      flex: 1 1 auto;
      background: #fff;
      position: relative;
      transition: all 0.4s;

      h4 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 8px 0;
        border-bottom: 1px solid #d3d3d3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;
      }

      .step-content {
        font-size: 12px;
        width: 95%;
        padding-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;
      }

      ion-icon {
        display: none;
      }

      &.current {
        border: 2px solid #ffffff;
        color: #4885ce;

        .step-content {
          color: #333;
        }
      }

      &.past {
        border: 1px solid #78b74a;
        background: #e7ffd5;
        color: #000;

        .step-content {
          color: #333;
        }

        &::after {
          content: " ";
          display: block;
          position: absolute;
          top: -10px;
          right: -10px;
          width: 30px;
          height: 30px;
          background: url("/assets/check-green.png") no-repeat center center;
          background-size: 90%;
        }
      }
    }

    .step-separator {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      min-width: 10px;
      flex: 1 1 auto;

      span {
        display: block;
        background: #ddd;
        height: 2px;
      }

      &.current span {
        // background: #666;
      }

      &.past span {
        // background: #666;
      }
    }

    .step-separator:last-of-type {
      display: none;
    }
  }
}

@media (min-width: 992px) and (max-width: 1070px) {
  .workflow-stepper-wrapper:not(.mobile-layout) .workflow-stepper .step {
    max-width: 120px;
  }
}

.workflow-stepper-wrapper.mobile-layout {
  overflow: hidden;
  background: var(--shading-color);

  .workflow-stepper {
    width: 90%;
    min-width: 300px;
    max-width: 500px;
    margin: auto;

    .step {
      background: #ddd;
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      border-radius: 100px;
      border: 1px solid transparent;
      padding: 0;

      h4 {
        opacity: 0;
      }

      .step-content {
        opacity: 0;
      }

      &.current {
        background: var(--ion-color-primary);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        &::before {
          content: " ";
          display: block;
          height: 25px;
          width: 25px;
          position: absolute;
          top: 41px;
          left: -2px;
          transform: rotate(45deg);
          background: var(--background);
          // border-left: 1px solid #eee;
          // border-top: 1px solid #ddd;
        }
      }

      &.past {
        background: var(--ion-color-secondary);
        background-size: 105%;

        ion-icon {
          display: block;
          position: absolute;
          top: -2px;
          left: 1px;
          font-size: 21px;
        }

        &::after {
          content: " ";
          display: none;
        }
      }
    }
  }
}

.ad-modal {
  backdrop-filter: blur(4px);

  &::part(content) {
    border-radius: 20px;
    height: 100%;
    max-height: 650px;
    max-width: 600px;
  }
}

.ad-modal-tall {
  backdrop-filter: blur(4px);

  &::part(content) {
    border-radius: 20px;
    height: 100%;
    max-height: 850px;
    width: 100%;
    max-width: 800px;
  }
}

.action-sheet-modal {
  align-items: flex-end;

  ion-backdrop {
    backdrop-filter: blur(2px);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.9);
  }

  &::part(content) {
    border-radius: 20px;
    min-height: 95%;
    min-width: 100%;
  }
}

.confirmation-icon {
  padding: 24px 0;
  margin: 16px 0 24px 0;
  text-align: center;
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 100px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  &.no-padding {
    padding: 0;
    margin: 0;
  }
}

.loader-backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
  z-index: 999999;

  .spinner-wrapper {
    // margin-top: 80px;
    transition: margin-top 0.2s;

    .spinner-background {
      border-radius: 16px;
      background: #333;
      padding: 32px;
      transform: scale(120%);
      transition: opacity 0.2s, transform 0.3s;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);

      ion-spinner {
        --color: var(--ion-color-light);
        //   color: var(--ion-color-primary);
        //   border-width: 2px;

        svg {
          stroke-width: 2px;
        }
      }
    }
  }

  &.show {
    opacity: 1;
    pointer-events: unset;

    .spinner-wrapper {
      //   margin-top: 0;

      .spinner-background {
        transform: scale(100%);
      }
    }

    .processing-stepper {
      opacity: 1 !important;
      margin-top: 0 !important;
    }
  }
}
